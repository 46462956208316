import React, { useState, useEffect, useCallback } from "react";
import "./App.css";
import logo from "./assets/logo.png"; // Ensure this path is correct
import NetworkSelector from "./networkSelector";
import {
  Connection,
  PublicKey,
  Keypair,
  StakeProgram,
  SystemProgram,
  Transaction,
  NonceAccount,
  LAMPORTS_PER_SOL,
  NONCE_ACCOUNT_LENGTH,
} from "@solana/web3.js"; // Import Solana web3 functions
import {
  getAssociatedTokenAddress,
  createAssociatedTokenAccountInstruction,
  createTransferInstruction,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import bs58 from "bs58";
import axios from "axios";

const BusyOverlay = ({ isVisible, message, detailedStatus }) => {
  if (!isVisible) return null;

  return (
    <div className="busy-overlay">
      <div className="busy-content">
        <div className="spinner"></div>
        <p className="main-message">{message}</p>
        {detailedStatus && <p className="detailed-status">{detailedStatus}</p>}
      </div>
    </div>
  );
};

const FAQItem = ({ question, answer, index }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="faq-card">
      <div
        onClick={() => setIsOpen(!isOpen)}
        style={{ cursor: "pointer", fontWeight: "bold" }}
      >
        {index + 1}. {question}
      </div>
      {isOpen && <p>{answer}</p>}
    </div>
  );
};

const App = () => {
  // State variables for managing stake accounts and NFTs
  const [stakeAccounts, setStakeAccounts] = useState([]);
  const [nfts, setNfts] = useState([]);

  // State for selected assets and recipient address
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [recipientAddress, setRecipientAddress] = useState("");

  // State for execution date and time
  const [executionDate, setExecutionDate] = useState("");
  const [executionTime, setExecutionTime] = useState("");

  // State for managing wills
  const [wills, setWills] = useState([]);

  // State for tracking the will creation process
  const [isCreatingWill, setIsCreatingWill] = useState(false);

  // State for transaction status and loading indicators
  const [transactionStatus, setTransactionStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [assetsLoaded, setAssetsLoaded] = useState(false);

  // State for error messages
  const [assetError, setAssetError] = useState("");
  const [recipientError, setRecipientError] = useState("");
  const [executionDateError, setExecutionDateError] = useState("");
  const [executionTimeError, setExecutionTimeError] = useState("");

  // State for detailed status and busy indicator
  const [detailedStatus, setDetailedStatus] = useState("");
  const [isBusy, setIsBusy] = useState(false);

  // State for debug messages
  const [debugMessage, setDebugMessage] = useState("");

  // State for managing the selected Solana network
  const [network, setNetwork] = useState(() => {
    // Try to get the network from localStorage, default to devnet if not found
    return (
      localStorage.getItem("selectedNetwork") || "https://api.devnet.solana.com"
    );
  });

  // Effect to update localStorage when network changes
  useEffect(() => {
    localStorage.setItem("selectedNetwork", network);
  }, [network]);

  // Access the connected wallet
  const wallet = useWallet();

  // Configuration options for Solana connection
  const opts = {
    preflightCommitment: "processed",
  };

  const API_URL = process.env.REACT_APP_API_URL || "http://localhost:5100/api";

  // Function to fetch wills associated with the connected wallet
  const fetchWills = useCallback(async () => {
    if (!wallet.publicKey) return []; // Ensure wallet is connected before fetching
    try {
      const response = await axios.get(
        `${API_URL}/wills?walletAddress=${wallet.publicKey.toString()}`
      );
      const fetchedWills = response.data;
      setWills(fetchedWills); // Update state with fetched wills
      return fetchedWills; // Return fetched wills for further processing
    } catch (error) {
      console.error("Error fetching wills:", error);
      return []; // Return an empty array on error
    }
  }, [wallet.publicKey, API_URL]);

  // Function to retrieve metadata for a given NFT mint address
  const getMetadata = useCallback(async (mintAddress, connection) => {
    // Metadata program ID for Solana NFTs
    const METADATA_PROGRAM_ID = new PublicKey(
      "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s"
    );

    // Derive the metadata account address for the given mint
    const [metadataAddress] = PublicKey.findProgramAddressSync(
      [
        Buffer.from("metadata"),
        METADATA_PROGRAM_ID.toBuffer(),
        new PublicKey(mintAddress).toBuffer(),
      ],
      METADATA_PROGRAM_ID
    );

    try {
      // Fetch the metadata account info from the Solana network
      const metadataAccount = await connection.getAccountInfo(metadataAddress);

      if (metadataAccount) {
        // Decode and return the metadata if the account exists
        const metadata = decodeMetadata(metadataAccount.data);
        return metadata; // Return the decoded metadata
      } else {
        console.log(`No metadata found for mint: ${mintAddress.toString()}`);
        return null; // Return null if no metadata
      }
    } catch (error) {
      console.error("Error fetching metadata:", error);
      return null;
    }
  }, []); // No dependencies for getMetadata as it's a pure function

  // Function to fetch and process stake accounts and NFTs for the connected wallet
  const getStakeAccountsAndNFTs = useCallback(async () => {
    // Clear debug message and transaction status
    setDebugMessage("");
    setTransactionStatus("");
    if (!wallet.publicKey) {
      console.log("Wallet not connected");
      return;
    }
    setLoading(true);
    setAssetsLoaded(false);
    const connection = new Connection(network, opts.preflightCommitment); // Create a connection to the Solana network

    try {
      // Fetch the latest wills data to determine which assets are already in use
      const alreadyExistingWills = await fetchWills();

      // Create sets to efficiently track existing assets
      const existingStakeAccounts = new Set();
      const existingNFTs = new Set();

      // Populate sets with assets from existing wills
      for (const will of alreadyExistingWills) {
        for (const asset of will.assets) {
          if (asset.type === "stake") {
            existingStakeAccounts.add(asset.id); // Add stake account to the set
          } else if (asset.type === "nft") {
            existingNFTs.add(asset.id); // Add NFT to the set
          }
        }
      }

      // Fetch stake accounts associated with the wallet
      const accounts = await connection.getParsedProgramAccounts(
        StakeProgram.programId,
        {
          filters: [
            {
              memcmp: {
                offset: 44,
                bytes: wallet.publicKey.toBase58(),
              },
            },
          ],
        }
      );

      // Process and format stake account data
      const stakeAccountsData = accounts.map((account) => ({
        pubkey: account.pubkey.toString(),
        lamports: account.account.lamports,
        disabled: existingStakeAccounts.has(account.pubkey.toString()), // Check if the account is already in use
      }));

      setStakeAccounts(stakeAccountsData);

      // Fetch all token accounts owned by the wallet
      const tokenAccounts = await connection.getParsedTokenAccountsByOwner(
        wallet.publicKey,
        {
          programId: TOKEN_PROGRAM_ID,
        }
      );

      const nftData = [];

      // Process token accounts to identify and fetch NFT metadata
      for (let i = 0; i < tokenAccounts.value.length; i++) {
        const tokenAccount = tokenAccounts.value[i];
        const tokenAmount = tokenAccount.account.data.parsed.info.tokenAmount;

        // Check if the token has the characteristics of an NFT (amount = 1, decimals = 0)
        if (tokenAmount.amount === "1" && tokenAmount.decimals === 0) {
          const mintAddress = tokenAccount.account.data.parsed.info.mint;
          const metadata = await getMetadata(
            new PublicKey(mintAddress),
            connection
          );

          if (metadata) {
            nftData.push({
              mint: mintAddress,
              name: metadata.data.name,
              symbol: metadata.data.symbol,
              uri: metadata.data.uri,
              disabled: existingNFTs.has(mintAddress), // Check if the NFT is already in use
            });
          }
        }
      }

      setNfts(nftData);
      setAssetsLoaded(true);
    } catch (error) {
      console.error("Error fetching accounts and NFTs:", error);
    } finally {
      setLoading(false);
    }
  }, [
    wallet.publicKey,
    opts.preflightCommitment,
    getMetadata,
    network,
    fetchWills,
  ]); // Dependencies include functions and values used within the callback

  // Effect to handle wallet connection changes
  useEffect(() => {
    // This effect will run when the wallet connects, disconnects, or changes accounts
    if (wallet.connected && wallet.publicKey) {
      // Wallet is connected, reset states and fetch new data
      setStakeAccounts([]);
      setNfts([]);
      setSelectedAssets([]);
      setAssetsLoaded(false);
      fetchWills(); // Fetch wills for the connected wallet
      getStakeAccountsAndNFTs(); // Fetch stake accounts and NFTs for the connected wallet
    } else {
      // Wallet is disconnected, reset all states
      setStakeAccounts([]);
      setNfts([]);
      setSelectedAssets([]);
      setAssetsLoaded(false);
    }
  }, [
    wallet.connected,
    wallet.publicKey,
    fetchWills,
    getStakeAccountsAndNFTs,
    setStakeAccounts,
    setNfts,
    setSelectedAssets,
    setAssetsLoaded,
  ]);

  // Function to decode NFT metadata from buffer
  function decodeMetadata(buffer) {
    try {
      let offset = 1; // Skip the first byte (usually a version number)

      offset += 64; // Skip fixed-size fields (if any)

      // Helper function to read a string from the buffer
      function readString() {
        const length = buffer.readUInt32LE(offset);
        offset += 4;
        const str = buffer.slice(offset, offset + length).toString("utf8");
        offset += length;
        return str;
      }

      // Read variable-length fields
      const name = readString();
      const symbol = readString();

      return {
        data: {
          name,
          symbol,
        },
      };
    } catch (error) {
      console.error("Error decoding metadata:", error);
      return null;
    }
  }

  // Function to handle asset selection/deselection
  const handleAssetSelection = (assetType, assetId) => {
    setSelectedAssets((prevSelected) => {
      const existingAsset = prevSelected.find((asset) => asset.id === assetId);
      if (existingAsset) {
        // If the asset is already selected, remove it
        return prevSelected.filter((asset) => asset.id !== assetId);
      } else {
        // If the asset is not selected, add it
        return [...prevSelected, { type: assetType, id: assetId }];
      }
    });
  };

  // Function to transfer assets and create a will
  const transferAssets = useCallback(async () => {
    // setIsBusy(true);
    const provider = wallet;
    const connection = new Connection(network, opts.preflightCommitment);

    try {
      setDetailedStatus("Checking wallet balance...");
      // Verify if the wallet has sufficient balance for the transfer
      const balance = await connection.getBalance(provider.publicKey);
      if (balance < 0.005 * LAMPORTS_PER_SOL) {
        setTransactionStatus("Insufficient balance to perform the transfer.");
        return;
      }

      setDetailedStatus("Creating Nonce Authority...");
      // Generate a new keypair for the nonce authority
      const nonceAuthKP = Keypair.generate();

      setDetailedStatus("Transferring SOL to nonce authority...");
      // Create an instruction to transfer SOL to the nonce authority
      const transferIx = SystemProgram.transfer({
        fromPubkey: provider.publicKey,
        toPubkey: nonceAuthKP.publicKey,
        lamports: 0.005 * LAMPORTS_PER_SOL,
      });

      // Create and sign the initial transaction
      const tx1 = new Transaction().add(transferIx);
      tx1.feePayer = provider.publicKey;
      tx1.recentBlockhash = (
        await connection.getLatestBlockhash("finalized")
      ).blockhash;

      setDetailedStatus("Signing and sending initial transaction...");
      const signedTx1 = await provider.signTransaction(tx1);
      const sig1 = await connection.sendRawTransaction(signedTx1.serialize());
      setDetailedStatus("Confirming initial transaction...");
      await connection.confirmTransaction(sig1, "finalized");

      // Create Nonce Account
      setDetailedStatus("Creating Nonce account...");
      const newNonceKeypair = Keypair.generate();
      // Store the nonce keypair in local storage for future use
      localStorage.setItem(
        "nonceKeypair",
        JSON.stringify(Array.from(newNonceKeypair.secretKey))
      );

      // Create and sign the nonce account creation transaction
      const tx2 = new Transaction();
      tx2.feePayer = nonceAuthKP.publicKey;
      tx2.recentBlockhash = (
        await connection.getLatestBlockhash("finalized")
      ).blockhash;
      tx2.add(
        SystemProgram.createAccount({
          fromPubkey: nonceAuthKP.publicKey,
          newAccountPubkey: newNonceKeypair.publicKey,
          lamports: await connection.getMinimumBalanceForRentExemption(
            NONCE_ACCOUNT_LENGTH
          ),
          space: NONCE_ACCOUNT_LENGTH,
          programId: SystemProgram.programId,
        }),
        SystemProgram.nonceInitialize({
          noncePubkey: newNonceKeypair.publicKey,
          authorizedPubkey: nonceAuthKP.publicKey,
        })
      );

      setDetailedStatus("Signing and sending nonce creation transaction...");
      tx2.sign(nonceAuthKP, newNonceKeypair);
      const sig2 = await connection.sendRawTransaction(tx2.serialize());
      setDetailedStatus("Confirming nonce creation transaction...");
      await connection.confirmTransaction(sig2, "finalized");

      // Verify the nonce account
      setDetailedStatus("Verifying nonce account...");
      const accountInfo = await connection.getAccountInfo(
        newNonceKeypair.publicKey
      );
      if (!accountInfo) {
        throw new Error("Nonce account not found or not initialized.");
      }

      const nonceAccount = NonceAccount.fromAccountData(accountInfo.data);

      // Create transfer instructions for selected assets
      setDetailedStatus("Creating transfer instructions...");
      const transferInstructions = [];

      for (const asset of selectedAssets) {
        if (asset.type === "stake") {
          setDetailedStatus(
            `Creating transfer instruction for stake account ${asset.id}...`
          );
          const stakePubkey = new PublicKey(asset.id);
          // Create instructions to authorize new staker and withdrawer
          const ix1 = StakeProgram.authorize({
            stakePubkey: stakePubkey,
            authorizedPubkey: provider.publicKey,
            newAuthorizedPubkey: new PublicKey(recipientAddress),
            stakeAuthorizationType: { index: 0 }, // StakeAuthorizationType.Staker
          });
          const ix2 = StakeProgram.authorize({
            stakePubkey: stakePubkey,
            authorizedPubkey: provider.publicKey,
            newAuthorizedPubkey: new PublicKey(recipientAddress),
            stakeAuthorizationType: { index: 1 }, // StakeAuthorizationType.Withdrawer
          });
          transferInstructions.push(ix1, ix2);
        } else if (asset.type === "nft") {
          setDetailedStatus(
            `Creating transfer instruction for NFT ${asset.id}...`
          );
          const nftMint = new PublicKey(asset.id);

          // Get the associated token account of the sender
          const senderTokenAccount = await getAssociatedTokenAddress(
            nftMint,
            provider.publicKey
          );

          // Get or create the associated token account of the recipient
          const recipientTokenAccount = await getAssociatedTokenAddress(
            nftMint,
            new PublicKey(recipientAddress)
          );

          // Check if the recipient's token account exists
          const recipientTokenAccountInfo = await connection.getAccountInfo(
            recipientTokenAccount
          );

          if (!recipientTokenAccountInfo) {
            // If the recipient's token account doesn't exist, create it
            transferInstructions.push(
              createAssociatedTokenAccountInstruction(
                provider.publicKey, // payer
                recipientTokenAccount, // associatedToken
                new PublicKey(recipientAddress), // owner
                nftMint // mint
              )
            );
          }

          // Create the transfer instruction for the NFT
          transferInstructions.push(
            createTransferInstruction(
              senderTokenAccount, // source
              recipientTokenAccount, // destination
              provider.publicKey, // owner
              1 // amount (1 for NFT)
            )
          );
        }
      }

      setDetailedStatus("Preparing final transaction...");
      // Create instruction to advance the nonce
      const advanceIX = SystemProgram.nonceAdvance({
        authorizedPubkey: nonceAuthKP.publicKey,
        noncePubkey: newNonceKeypair.publicKey,
      });

      // Construct the final transaction
      const tx3 = new Transaction();
      tx3.add(advanceIX);
      tx3.add(...transferInstructions);
      tx3.recentBlockhash = nonceAccount.nonce;
      tx3.feePayer = provider.publicKey;
      tx3.sign(nonceAuthKP);

      setDetailedStatus("Signing final transaction...");
      const signedtx3 = await provider.signTransaction(tx3);
      const serialisedTx3 = bs58.encode(
        signedtx3.serialize({ requireAllSignatures: false })
      );

      // Prepare the will data to be saved
      const executionDateTime = new Date(`${executionDate}T${executionTime}`);
      const executionTimeUTC = executionDateTime
        .toISOString()
        .split("T")[1]
        .slice(0, 5);

      const newWill = {
        recipient: recipientAddress,
        assets: selectedAssets,
        nonce: serialisedTx3,
        executionDate: new Date(`${executionDate}T${executionTime}`),
        executionTime: executionTimeUTC,
        walletAddress: provider.publicKey.toString(),
        network: network,
      };

      try {
        // Save the will to the backend
        await axios.post(`${API_URL}/wills`, newWill);
        setTransactionStatus("Will created successfully!");

        // Fetch the updated list of wills and assets
        fetchWills();
        getStakeAccountsAndNFTs();
      } catch (error) {
        console.error("Error saving will:", error);
        setTransactionStatus("Failed to save will.");
      }

      setDetailedStatus("");

      // Clear form fields after successful will creation
      setRecipientAddress("");
      setSelectedAssets([]);
      setExecutionDate("");
      setExecutionTime("");
    } catch (err) {
      console.error("Transaction error: ", err);
      setTransactionStatus("Transaction failed or timed out.");
      setDetailedStatus(`Error: ${err.message}`);
    } finally {
      setIsCreatingWill(false);
      // setIsBusy(false);
    }
  }, [
    wallet,
    network,
    opts.preflightCommitment,
    recipientAddress,
    selectedAssets,
    executionDate,
    executionTime,
    fetchWills,
    getStakeAccountsAndNFTs,
    API_URL,
  ]);

  // Function to handle the transfer process
  const handleTransfer = useCallback(async () => {
    // Reset error states
    setAssetError("");
    setRecipientError("");
    setExecutionDateError("");
    setExecutionTimeError("");

    let isValid = true;

    // Validate selected assets
    if (selectedAssets.length === 0) {
      setAssetError("Please select at least one asset to transfer.");
      isValid = false;
    }

    // Validate recipient address
    if (!recipientAddress) {
      setRecipientError("Recipient address is required.");
      isValid = false;
    }

    // Prevent self-transfer
    if (recipientAddress === wallet.publicKey.toString()) {
      setRecipientError("Cannot transfer assets to the same wallet.");
      isValid = false;
    }

    // Validate execution date
    if (!executionDate) {
      setExecutionDateError("Execution date is required.");
      isValid = false;
    }

    // Validate execution time
    if (!executionTime) {
      setExecutionTimeError("Execution time is required.");
      isValid = false;
    }

    // Ensure execution time is at least 2 minutes in the future
    const selectedDateTime = new Date(`${executionDate}T${executionTime}`);
    const currentDate = new Date();

    if (
      selectedDateTime < currentDate ||
      selectedDateTime < new Date(currentDate.getTime() + 2 * 60000)
    ) {
      setExecutionTimeError(
        "Execution date and time must be at least 2 minutes in the future."
      );
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    // Proceed with transfer if all validations pass
    setIsBusy(true);
    setIsCreatingWill(true);
    setTransactionStatus("Create Will...");
    setDetailedStatus("");
    try {
      await transferAssets();

      // Refresh the asset list after the transfer
      setDetailedStatus("Fetching updated stake accounts and NFTs...");
      await getStakeAccountsAndNFTs();
    } catch (error) {
      console.error("Error creating will:", error);
      setTransactionStatus("Failed to create will.");
    } finally {
      setIsBusy(false);
      setDetailedStatus("");
      setIsCreatingWill(false);
    }
  }, [
    wallet.publicKey,
    executionDate,
    executionTime,
    recipientAddress,
    selectedAssets,
    getStakeAccountsAndNFTs,
    transferAssets,
    setAssetError,
    setRecipientError,
    setExecutionDateError,
    setExecutionTimeError,
    setTransactionStatus,
    setDetailedStatus,
    setIsBusy,
    setIsCreatingWill,
  ]);

  // Function to execute a transfer of assets based on a will
  async function executeTransfer(will) {
    const connection = new Connection(network, opts.preflightCommitment);

    try {
      // Fetch the will from the database if not provided
      let fetchedWill = will;
      if (!will) {
        const response = await axios.get(`${API_URL}/wills/${will._id}`);
        fetchedWill = response.data;
      }

      // Retrieve the durable nonce from the fetched will
      const storedNonce = fetchedWill.nonce;
      if (!storedNonce) {
        throw new Error("No durable nonce found in the will.");
      }

      // Decode the stored nonce transaction
      const tx = bs58.decode(storedNonce);

      // Verify that all assets in the will still exist and are owned by the wallet
      for (const asset of fetchedWill.assets) {
        if (asset.type === "stake") {
          const stakeAccountInfo = await connection.getAccountInfo(
            new PublicKey(asset.id)
          );
          if (!stakeAccountInfo) {
            throw new Error(
              `Stake account ${asset.id} not found or not initialized.`
            );
          }
        } else if (asset.type === "nft") {
          const tokenAccounts = await connection.getTokenAccountsByOwner(
            wallet.publicKey,
            { mint: new PublicKey(asset.id) }
          );
          if (tokenAccounts.value.length === 0) {
            throw new Error(`NFT ${asset.id} is no longer in the wallet.`);
          }
        }
      }

      // Send the durable transaction to the network
      await connection.sendRawTransaction(tx);

      // Remove the executed will from the database
      await axios.delete(`${API_URL}/wills/${fetchedWill._id}`);

      // Update the local state to remove the executed will
      setWills((prevWills) =>
        prevWills.filter((w) => w._id !== fetchedWill._id)
      );

      setTransactionStatus("Transfer executed successfully!");
      setDebugMessage("Transfer executed successfully!");
    } catch (err) {
      console.error("Transaction error: ", err);
      setTransactionStatus("Transfer execution failed: " + err.message);
      setDebugMessage("Transfer execution failed: " + err.message);
    }
  }

  // Function to cancel a will and remove it from the database
  const cancelWill = async (will) => {
    try {
      // Delete the will from the database
      await axios.delete(`${API_URL}/wills/${will._id}`);

      // Update the local state to remove the canceled will
      setWills((prevWills) => prevWills.filter((w) => w._id !== will._id));

      alert("Will canceled successfully!");
      setDebugMessage(`Will for ${will.recipient} canceled successfully.`);
    } catch (error) {
      console.error("Error canceling will:", error);
      alert("Failed to cancel the will.");
      setDebugMessage(
        `Failed to cancel will for ${will.recipient}: ${error.message}`
      );
    }
  };

  // Function to handle the execution of a will
  const handleExecute = async (will) => {
    if (
      window.confirm(
        `Are you sure you want to execute the will for ${will.recipient}?`
      )
    ) {
      setDebugMessage(`Executing will for ${will.recipient}...`);
      await executeTransfer(will);
      setDetailedStatus("Fetching updated stake accounts and NFTs...");
      await getStakeAccountsAndNFTs();
    }
  };

  // Function to handle the cancellation of a will
  const handleCancel = async (will) => {
    if (
      window.confirm(
        `Are you sure you want to cancel the will for ${will.recipient}?`
      )
    ) {
      setDebugMessage(`Canceling will for ${will.recipient}...`);
      await cancelWill(will);
      setDetailedStatus("Fetching updated stake accounts and NFTs...");
      await getStakeAccountsAndNFTs();
    }
  };

  return (
    <div className="App">
      <BusyOverlay
        isVisible={isBusy}
        message={transactionStatus}
        detailedStatus={detailedStatus}
      />
      <div class="asset-row page-title">
        <span class="asset-details">
          <img src={logo} alt="Logo" className="logo" />{" "}
          <h4>Secure Your Digital Legacy!</h4>
        </span>
        <span class="asset-details" style={{ textAlign: "right" }}>
        <span>
          {" "}
          <div className="wallet-dropdown">
            <WalletMultiButton />
          </div>
        </span>
        <span style={{ marginBottom: "20px", marginTop: "0px" }}>
          {" "}
          <div className="network-dropdown">
            <NetworkSelector network={network} setNetwork={setNetwork} />
          </div>
        </span>
        </span>
      </div>

      <div class="account-container">
        {wallet.connected ? (
          ""
        ) : (
          <div className="no-assets-message">Wallet not connected</div>
        )}
        {loading && <p>Loading...</p>}
        {!loading && assetsLoaded && (
          <div>
            <div>{assetError && <p className="error">{assetError}</p>}</div>
            <div class="title-row">
              <span class="asset-details">
                <h2 style={{ marginTop: "0px", marginBottom: "5px" }}>
                  Create Will
                </h2>
              </span>
              <span>
                <div class="refresh-button">
                  <button
                    onClick={getStakeAccountsAndNFTs}
                    disabled={!wallet.connected}
                  >
                    ⟳
                  </button>
                </div>
              </span>
            </div>

            <div class="asset-row">
              <span class="asset-details ">
                <h3>Stake Accounts</h3>
              </span>
            </div>
            {stakeAccounts.length > 0 ? ( // Check if there are stake accounts
              <div className="asset-list">
                {stakeAccounts.map((account, index) => (
                  <div
                    key={index}
                    className={`asset-item ${
                      account.disabled ? "asset-locked" : ""
                    }`}
                    title={
                      account.disabled
                        ? "This asset is already included in a will"
                        : ""
                    }
                  >
                    <input
                      type="checkbox"
                      checked={selectedAssets.some(
                        (asset) => asset.id === account.pubkey
                      )}
                      onChange={() =>
                        handleAssetSelection("stake", account.pubkey)
                      }
                      disabled={account.disabled}
                      className="asset-checkbox"
                    />
                    <span
                      className={`asset-details ${
                        account.disabled ? "asset-disabled" : ""
                      }`}
                    >
                      {account.pubkey.substr(0, 4)}..{account.pubkey.substr(-4)}
                    </span>
                    <span>{account.lamports / LAMPORTS_PER_SOL} SOL</span>
                  </div>
                ))}
              </div>
            ) : (
              <div className="no-assets-message">
                No stake accounts available
              </div> // Message when the list is empty
            )}

            <div class="asset-row">
              <span class="asset-details ">
                <h3>NFTs</h3>
              </span>
            </div>
            {nfts.length > 0 ? ( // Check if there are NFTs
              <div className="asset-list">
                {nfts.map((nft, index) => (
                  <div
                    className={`asset-item ${
                      nft.disabled ? "asset-locked" : ""
                    }`}
                    title={
                      nft.disabled
                        ? "This asset is already included in a will"
                        : ""
                    }
                  >
                    <input
                      type="checkbox"
                      checked={selectedAssets.some(
                        (asset) => asset.id === nft.mint
                      )}
                      onChange={() => handleAssetSelection("nft", nft.mint)}
                      disabled={nft.disabled}
                      className="asset-checkbox"
                    />
                    <span
                      className={`asset-details ${
                        nft.disabled ? "asset-disabled" : ""
                      }`}
                    >
                      {nft.name} ({nft.symbol}) - {nft.mint.substr(0, 4)}..
                      {nft.mint.substr(-4)}
                    </span>
                  </div>
                ))}
              </div>
            ) : (
              <div className="no-assets-message">No NFTs available</div> // Message when the list is empty
            )}

            <div className="create-will-container">
              <div>
                {recipientError && <p className="error">{recipientError}</p>}
                <span>Recipient</span>{" "}
                <input
                  type="text"
                  placeholder="Recipient Solana Address"
                  value={recipientAddress}
                  onChange={(e) => setRecipientAddress(e.target.value)}
                />
              </div>
              <div>
                {executionDateError && (
                  <p className="error">{executionDateError}</p>
                )}
                Execution Date{" "}
                <input
                  type="date"
                  value={executionDate}
                  onChange={(e) => setExecutionDate(e.target.value)}
                />
              </div>
              <div>
                {executionTimeError && (
                  <p className="error">{executionTimeError}</p>
                )}
                Execution Time{" "}
                <input
                  type="time"
                  value={executionTime}
                  onChange={(e) => setExecutionTime(e.target.value)}
                />
              </div>

              <button
                class="action"
                onClick={handleTransfer}
                disabled={isCreatingWill || isBusy || !wallet.connected}
              >
                {isCreatingWill ? "Creating Will..." : "Create"}
              </button>
              {transactionStatus && (
                <p
                  className={`transaction-status ${
                    transactionStatus.includes("successfully")
                      ? "success"
                      : "error"
                  }`}
                >
                  {transactionStatus}
                </p>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="wills-container">
        <h2>Wills</h2>
        {wills.length > 0 ? ( // Check if there are NFTs
          <ul>
            {wills.map((will, index) => (
              <li key={index} className="will-item">
                <div>
                  <strong>Recipient:</strong> {will.recipient.substr(0, 4)}..
                  {will.recipient.substr(-4)}
                  <br />
                  <strong>Execution Time:</strong>{" "}
                  {new Date(will.executionDate).toLocaleString()} <br />
                  <strong>Assets:</strong>
                  <ul>
                    {will.assets.map((asset, assetIndex) => (
                      <li key={assetIndex}>
                        {asset.type === "stake" ? "Stake: " : "NFT: "}
                        {asset.id.substr(0, 4)}..{asset.id.substr(-4)}
                      </li>
                    ))}
                  </ul>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <button
                    className="action"
                    onClick={() => handleExecute(will)}
                  >
                    Execute
                  </button>
                  <button className="action" onClick={() => handleCancel(will)}>
                    Cancel
                  </button>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div className="no-assets-message">No Wills Found</div> // Message when the list is empty
        )}
      </div>
      <div className="debug-info">
        {debugMessage && <p>{debugMessage}</p>} {/* Display debug message */}
      </div>
      {/* FAQ Section */}
      <div className="faq-section">
        <h2>Frequently Asked Questions (FAQ)</h2>
        <FAQItem
          question="What is SolHeir?"
          answer="SolHeir is your trusted digital estate manager, powered by the Solana blockchain. It offers a secure and automated way to handle and pass on your digital assets. With SolHeir, you can easily set up digital wills, manage stake accounts, and ensure your assets are distributed according to your wishes—no intermediaries needed. Think of it as the modern, hassle-free way to safeguard your digital legacy!"
          index={0}
        />
        <FAQItem
          question="How does it work?"
          answer="It's Simple! Start by connecting your preferred wallet. Next, select the assets you'd like to include in your digital will. Enter the recipient's address and set the date and time for the will to be executed. Hit 'Create Will,' and voilà! Your will is securely stored on the blockchain, ready to be automatically executed at the designated time—no further action needed."
          index={1}
        />
        <FAQItem
          question="What types of assets can I include in my digital will?"
          answer="Currently, you can include staked accounts and NFTs in your digital will. SolHeir ensures these assets are transferred seamlessly to your chosen beneficiaries at the right time. We're also working on adding token support in the near future, so stay tuned!"
          index={2}
        />
        <FAQItem
          question="Can I change or cancel my will after it's created?"
          answer="Yes! SolHeir allows you to cancel your digital will at any time. Simply log into your account, make the necessary changes."
          index={3}
        />
        <FAQItem
          question="Is SolHeir safe to use?"
          answer="Absolutely! SolHeir leverages the robust security of the Solana blockchain to ensure your assets and information are fully protected."
          index={4}
        />
      </div>

      {/* Footer Section */}
      <footer className="footer">
        <div className="social-icons">
          <a
            href="https://x.com/solheir_"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg width="48" height="48" viewBox="0 0 24 24">
              <path
                d="M 12 2.184 a 4.83 4.83 0 0 1 -1.415 0.397 a 2.52 2.52 0 0 0 1.083 -1.396 a 4.87 4.87 0 0 1 -1.564 0.612 A 2.428 2.428 0 0 0 8.308 1 c -1.36 0 -2.463 1.13 -2.463 2.524 c 0 0.198 0.023 0.39 0.065 0.576 C 3.863 3.994 2.05 2.99 0.835 1.46 a 2.564 2.564 0 0 0 -0.332 1.27 a 2.54 2.54 0 0 0 1.094 2.102 a 2.413 2.413 0 0 1 -1.115 -0.316 v 0.032 c 0 1.224 0.849 2.243 1.974 2.476 c -0.363 0.1 -0.743 0.115 -1.112 0.042 c 0.314 1.002 1.223 1.734 2.3 1.754 A 4.857 4.857 0 0 1 0 9.866 A 6.83 6.83 0 0 0 3.774 11 c 4.528 0 7.005 -3.847 7.005 -7.182 c 0 -0.11 -0.003 -0.22 -0.007 -0.327 c 0.482 -0.358 0.898 -0.8 1.228 -1.308 Z"
                fill="white"
              />
            </svg>
          </a>
          {/* Add more social icons as needed */}
        </div>
        <p>&copy; {new Date().getFullYear()} SolHeir. All rights reserved.</p>
      </footer>
    </div>
  );
};
export default App;
