import React from 'react';
import styled from 'styled-components';

const SelectContainer = styled.div`
  display: inline-block;
  margin: 1px;
`;

const StyledSelect = styled.select`
  padding: 5px 1px;
  font-size: 14px;
  border: 0px solid rgb(204, 204, 204);
  border-radius: 4px;
  background-color: #eee;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: #4CAF50;
    box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
  }
`;

const NetworkSelector = ({ network, setNetwork }) => {
  const networks = [
    { name: 'Testnet', url: 'https://api.testnet.solana.com' },
    { name: 'Devnet', url: 'https://api.devnet.solana.com' },
  ];

  const handleNetworkChange = (event) => {
    setNetwork(event.target.value);
  };

  return (
    <SelectContainer>
      <StyledSelect value={network} onChange={handleNetworkChange}>
        {networks.map((net) => (
          <option key={net.name} value={net.url}>
            {net.name}
          </option>
        ))}
      </StyledSelect>
    </SelectContainer>
  );
};

export default NetworkSelector;